@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.clientesPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 78px;
  margin-bottom: -40px;
}

.descriptionSection {
  width: 80%;
  max-width: 1440px;
  gap: 50px;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  text-align: center;
}

#clientesTitle {
  font-family: "Inter";
  font-size: 48px;
  font-weight: 600;
  color: #1f497e;
}

#clientesText {
  font-family: "Inter";
  font-size: 22px;
  font-weight: 300;
  line-height: 1.3;
  text-align: justify;
  letter-spacing: 0.1em;

  color: #3b3b3b;
}

.logosSection {
  margin: 70px 0 40px 0;
  width: 80%;
  max-width: 1440px;

  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  gap: 80px;
}

.logoBox {
  width: fit-content;

  display: flex;
  justify-content: center;
  align-items: center;
}

#logoImg {
  height: 50px;
}

@media screen and (max-width: 480px) {
  .clientesPage {
    padding-top: 40px;
  }

  #clientesTitle {
    width: 100%;
    display: flex;
    justify-content: center;
    text-align: center;
    font-size: 28px;
  }

  .logosSection {
    margin: 30px 0;

    flex-direction: column;
    align-items: center;

    gap: 15px;
  }

  .logoBox {
    margin: 8px 0;
  }

  #logoImg {
    height: 40px;
  }
}

@media screen and (min-width: 480px) and (max-width: 768px) {
  .clientesPage {
    padding-top: 40px;
  }

  #clientesTitle {
    width: 100%;
    display: flex;
    justify-content: center;
    text-align: center;
    font-size: 34px;
  }

  .logosSection {
    margin: 30px 0;
    gap: 20px;
  }

  .logoBox {
    margin: 8px 0;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #clientesTitle {
    width: 100%;
    display: flex;
    justify-content: center;
    text-align: center;
    font-size: 4.65vw;
  }

  .logosSection {
    margin: 30px 0;
    gap: 20px;
  }

  .logoBox {
    margin: 8px 0;
  }
}