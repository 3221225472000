@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");

* {
  padding: 0;
  margin: 0;
}

.main {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sobreSection {
  width: 80%;
  max-width: 1440px;
  padding: 70px 0;
  gap: 50px;

  display: flex;
  flex-direction: column;
  align-items: center;
}

#sobreTitle {
  font-family: "Inter";
  font-size: 48px;
  font-weight: 600;
  color: #1f497e;
}

#textSobre {
  font-family: "Inter";
  font-size: 22px;
  font-weight: 300;
  line-height: 1.3;
  text-align: justify;
  letter-spacing: 0.1em;

  color: #3b3b3b;
}

.produtosSection {
  width: 100%;
  padding: 70px 0;
  gap: 50px;

  display: flex;
  flex-direction: column;
  align-items: center;

  background: linear-gradient(to left, rgba(0, 0, 0, 0.3), transparent);
  background-color: #1f497e;
}

#produtosTitle {
  font-family: "Inter";
  font-size: 48px;
  font-weight: 400;
  color: #fff;
}

.produtosDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 40px;
}

.logoSection {
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 40px;
}

#clientes {
  font-family: "Inter";
  font-size: 48px;
  font-weight: 500;
  color: #1f497e;
}

.logosHorizontal {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 5%;
  flex-wrap: wrap;
}

#logoItem {
  height: 50px;
}

@media screen and (max-width: 480px) {
  .sobreSection {
    gap: 35px;
    padding: 30px 0;
  }

  #sobreTitle {
    width: 100%;
    display: flex;
    justify-content: center;
    text-align: center;
    font-size: 28px;
  }

  #textSobre {
    font-size: 16px;
    text-align: center;
  }

  .produtosSection {
    gap: 25px;
    padding: 35px 0;
  }

  #produtosTitle {
    width: 100%;
    display: flex;
    justify-content: center;
    text-align: center;
    font-size: 28px;
  }

  .produtosDiv {
    margin-bottom: 45px;
  }

  .logoSection {
    margin-top: 10px;
    margin-bottom: -20px;
    gap: 20px;
  }

  #logoDiv {
    margin: 8px 0;
    width: 100%;
    display: flex;
    justify-content: center;
  }

  #logoItem {
    height: 40px;
  }

  #clientes {
    width: 100%;
    display: flex;
    justify-content: center;
    text-align: center;
    font-size: 28px;
  }
}

@media screen and (min-width: 480px) and (max-width: 768px) {
  .sobreSection {
    gap: 55px;
    padding: 55px 0;
  }

  #sobreTitle {
    width: 100%;
    display: flex;
    justify-content: center;
    text-align: center;
    font-size: 34px;
  }

  #textSobre {
    font-size: 16px;
  }

  .produtosSection {
    gap: 35px;
    padding: 35px 0;
  }

  #produtosTitle {
    width: 100%;
    display: flex;
    justify-content: center;
    text-align: center;
    font-size: 34px;
  }

  .produtosDiv {
    margin-bottom: 45px;
  }

  .logoSection {
    margin-top: 30px;
    gap: 20px;
  }

  #logoDiv {
    margin: 8px 0;
    width: 100%;
    display: flex;
    justify-content: center;
  }

  #clientes {
    width: 100%;
    display: flex;
    justify-content: center;
    text-align: center;
    font-size: 34px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #sobreTitle {
    width: 100%;
    display: flex;
    justify-content: center;
    text-align: center;
    font-size: 4.65vw;
  }

  #textSobre {
    font-size: 2.2vw;
  }

  #produtosTitle {
    width: 100%;
    display: flex;
    justify-content: center;
    text-align: center;
    font-size: 4.65vw;
  }

  .logoSection {
    margin-top: 30px;
    gap: 20px;
  }

  #logoDiv {
    margin: 8px 0;
  }

  #clientes {
    width: 100%;
    display: flex;
    justify-content: center;
    text-align: center;
    font-size: 4.65vw;
  }
}
