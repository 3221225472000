@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.mainProduto {
  margin-bottom: -82px;
}

.sobreSection {
  background: linear-gradient(to left, rgba(0, 0, 0, 0.3), transparent);
  background-color: #1f497e;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  gap: 60px;
  padding: 80px 0;
}

.sobreDiv {
  width: 80%;
  max-width: 1440px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  gap: 20px;
}

#sobreTitle {
  font-family: "Inter";
  font-size: 48px;
  font-weight: 600;
  text-align: center;
  color: #fff;

  width: 80%;
}

#sobreDescription {
  font-family: "Inter";
  font-size: 22px;
  font-weight: 300;
  line-height: 1.3;
  text-align: justify;
  letter-spacing: 0.1em;
  color: #fff;
}

.imagesConitecAns {
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  padding: 40px 0;
}

.imagesDiv {
  width: 80%;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

#produtos07 {
  height: 350px;
}

@media screen and (max-width: 480px) {
  .sobreSection {
    gap: 35px;
    padding: 30px 0;
  }

  #sobreTitle {
    width: 100%;
    display: flex;
    justify-content: center;
    text-align: center;
    font-size: 28px;
  }

  #sobreDescription {
    font-size: 16px;
    text-align: center;
  }
}

@media screen and (min-width: 480px) and (max-width: 768px) {
  .sobreSection {
    gap: 55px;
    padding: 55px 0;
  }

  #sobreTitle {
    width: 100%;
    display: flex;
    justify-content: center;
    text-align: center;
    font-size: 34px;
  }

  #sobreDescription {
    font-size: 16px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #sobreTitle {
    width: 100%;
    display: flex;
    justify-content: center;
    text-align: center;
    font-size: 4.65vw;
  }

  #sobreDescription {
    font-size: 2.2vw;
  }
}
