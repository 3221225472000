@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.sobrePage {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: -80px;
}

.sobreSection {
  width: 100%;
  padding: 70px 0;
  gap: 50px;

  display: flex;
  flex-direction: column;
  align-items: center;

  background: linear-gradient(to left, rgba(0, 0, 0, 0.3), transparent);
  background-color: #1f497e;
}

#sobreTitle {
  font-family: "Inter";
  font-size: 48px;
  font-weight: 600;
  color: #fff;
}

#textSobre {
  font-family: "Inter";
  font-size: 22px;
  font-weight: 300;
  line-height: 1.3;
  text-align: justify;
  letter-spacing: 0.1em;
  color: #fff;
  width: 80%;
  max-width: 1440px;
}

.visaoSection {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 80%;
  max-width: 1440px;
  padding: 70px 0;
  gap: 40px;
}

.visaoContent {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

#visaoTitle {
  font-family: "Inter";
  font-size: 40px;
  font-weight: 600;
  color: #1f497e;
}

#visaoText {
  font-family: "Inter";
  font-size: 20px;
  font-weight: 400;
  line-height: 1.3;
  text-align: justify;
  color: #3b3b3b;
}

.valoresSection {
  width: 80%;
  max-width: 1440px;
  padding-bottom: 70px;
  gap: 50px;

  display: flex;
  flex-direction: column;
  align-items: center;
}

#valoresTitle {
  font-family: "Inter";
  font-size: 40px;
  font-weight: 600;
  color: #1f497e;
}

.valoresIconsDiv {
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;
}

#valoresIcon {
  border: 1px solid #1f497e;
  border-radius: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  width: 184px;
  height: 184px;
}

#icons {
  font-family: "Inter";
  font-size: 14px;
  font-weight: 700;
  color: #1f497e;
  line-height: 1.2;

  width: 83%;
  text-align: center;
  transition: all 0.6s ease-in-out;
}

#icons:hover {
  transform: scale(1.1);
}

.equipeSection {
  width: 100%;
  padding: 70px 0;
  gap: 50px;

  display: flex;
  flex-direction: column;
  align-items: center;

  background: linear-gradient(to left, rgba(0, 0, 0, 0.3), transparent);
  background-color: #1f497e;
}

#equipeTitle {
  font-family: "Inter";
  font-size: 48px;
  font-weight: 400;
  color: #fff;
}

.equipeDiv {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  gap: 40px;

  width: 80%;
  max-width: 1440px;
  margin-bottom: 20px;
}

.valoresIconsDivMobile {
  display: none;
}

@media screen and (max-width: 480px) {
  .sobreSection,
  .equipeSection {
    padding: 35px 0;
    gap: 30px;
  }

  #sobreTitle,
  #equipeTitle {
    width: 100%;
    display: flex;
    justify-content: center;
    text-align: center;
    font-size: 28px;
  }

  #textSobre {
    font-size: 16px;
    text-align: center;
  }

  .visaoSection {
    flex-direction: column;
    align-items: center;
    padding: 35px 0;
    gap: 20px;
  }

  .imageVisao,
  #imageVisao {
    width: 300px;
  }

  #visaoTitle,
  #valoresTitle {
    width: 100%;
    display: flex;
    justify-content: center;
    text-align: center;
    font-size: 28px;
  }

  #visaoText {
    font-size: 16px;
    text-align: center;
  }

  .valoresSection {
    margin-top: -15px;
    padding-bottom: 30px;
    gap: 15px;
  }

  .valoresIconsDiv {
    display: none;
  }

  .valoresIconsDivMobile {
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 10px;
  }

  .listValores {
    /* list-style: none; */
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 10px;
  }

  #valoresIcon {
    min-width: 5px;
    min-height: 5px;
    max-width: 5px;
    max-height: 5px;
    background-color: #1f497e;
  }

  #icons {
    font-size: 12px;
    width: 90%;
    text-align: left;
  }

  .equipeDiv {
    gap: 40px;
  }
}

@media screen and (min-width: 480px) and (max-width: 768px) {
  .sobreSection,
  .equipeSection {
    padding: 50px 0;
    gap: 35px;
  }

  #sobreTitle,
  #equipeTitle {
    width: 100%;
    display: flex;
    justify-content: center;
    text-align: center;
    font-size: 34px;
  }

  #textSobre {
    font-size: 16px;
    text-align: center;
  }

  .visaoSection {
    flex-direction: column;
    align-items: center;
    padding: 50px 0;
    gap: 20px;
  }

  .imageVisao,
  #imageVisao {
    width: 300px;
  }

  #visaoTitle,
  #valoresTitle {
    width: 100%;
    display: flex;
    justify-content: center;
    text-align: center;
    font-size: 28px;
  }

  #visaoText {
    font-size: 16px;
    text-align: center;
  }

  .valoresSection {
    margin-top: -20px;
    padding-bottom: 30px;
    gap: 15px;
  }

  .valoresIconsDiv {
    display: none;
  }

  .valoresIconsDivMobile {
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 10px;
  }

  .listValores {
    /* list-style: none; */
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }

  #valoresIcon {
    min-width: 5px;
    min-height: 5px;
    max-width: 5px;
    max-height: 5px;
    background-color: #1f497e;
  }

  #icons {
    font-size: 12px;
    width: 90%;
    text-align: left;
  }

  .equipeDiv {
    gap: 40px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #sobreTitle,
  #equipeTitle {
    width: 100%;
    display: flex;
    justify-content: center;
    text-align: center;
    font-size: 4.65vw;
  }

  #textSobre {
    font-size: 2.2vw;
  }

  .imageVisao,
  #imageVisao {
    width: 300px;
  }

  #visaoTitle,
  #valoresTitle {
    font-size: 3.9vw;
  }

  #visaoText {
    font-size: 2.2vw;
  }

  .valoresSection {
    margin-top: -30px;
    padding-bottom: 35px;
    gap: 20px;
  }

  .valoresIconsDiv {
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
  }

  .valoresIconsDivMobile {
    display: none;
  }

  #valoresIcon {
    width: 154px;
    height: 154px;
  }

  #icons {
    font-size: 12px;
  }

  .equipeDiv {
    gap: 40px;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1240px) {
  .imageVisao,
  #imageVisao {
    width: 360px;
  }

  .valoresIconsDiv {
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
  }

  .valoresIconsDivMobile {
    display: none;
  }

  .equipeDiv {
    flex-wrap: wrap;
    gap: 40px;
  }
}
