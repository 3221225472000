@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.mainEquipe {
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
  margin-bottom: -80px;
}

.ceoSection {
  display: flex;
  align-items: flex-start;
  width: 80%;
  max-width: 1440px;
  padding: 80px 0;
}

#ceoImage {
  display: flex;
  height: 380px;
  transition: 0.5s ease-in-out;
}

#ceoImage:hover {
  transform: scale(1.025);
}

.ceoContent {
  display: flex;
  flex-direction: column;
  align-items: start;
  padding: 30px;
  gap: 20px;

  margin-left: 20px;
}

#ceoTitle {
  font-family: "Inter";
  font-size: 42px;
  font-weight: 600;
  text-align: justify;
  color: #1f497e;
  cursor: pointer;
  margin-top: -20px;
}

#ceoDescription {
  font-family: "Inter";
  font-size: 16px;
  font-weight: 400;
  line-height: 1.3;
  text-align: justify;
  letter-spacing: 0.07em;
  color: #1f497e;
}

#rodrigoList {
  list-style: none;

  font-family: "Inter";
  font-size: 16px;
  font-weight: 400;
  line-height: 1.3;
  text-align: left;
  letter-spacing: 0.07em;
  color: #1f497e;
}

.equipeSection {
  width: 100%;
  padding: 88px 0;

  display: flex;
  justify-content: center;

  background: linear-gradient(to left, rgba(0, 0, 0, 0.3), transparent);
  background-color: #1f497e;
}

.colaboradoresDiv {
  display: flex;
  justify-content: space-around;
  align-items: start;

  width: 80%;
  max-width: 1440px;
}

#equipeCategoriaMedDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* width: 100%; */
  width: fit-content;
  gap: 20px;
}

#equipeCategoriaTecDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* width: 100%; */
  width: fit-content;
  gap: 20px;
}

#lineShape {
  border-right: 1px solid #ffffff;
  height: 700px;
  margin-top: 55px;
}

#equipeCategoriaTitle,
#equipeCategoriaTitle {
  font-family: "Inter";
  font-size: 32px;
  font-weight: 600;
  text-align: left;
  letter-spacing: 0.03em;
  color: #ffffff;

  /* padding-left: 3.5rem; */
  width: 100%;
}

.medicosDiv1 {
  display: flex;
  justify-content: center;

  gap: 40px;
  width: 80%;
  flex-wrap: nowrap;

  margin-bottom: 80px;
}

.medicosDiv2 {
  display: flex;
  justify-content: center;

  gap: 40px;
  width: 80%;
  flex-wrap: nowrap;

  margin-bottom: 80px;
}

.tecnicosDiv {
  display: flex;
  justify-content: center;

  gap: 40px;
  width: 80%;
  flex-wrap: nowrap;

  margin-bottom: 40px;
}

@media screen and (max-width: 480px) {
  .ceoSection {
    width: 90%;
    flex-direction: column;
    align-items: center;
    padding: 30px 0;
    margin-bottom: -60px;
  }

  #colaboradorImg {
    width: 100%;
    height: auto;
    overflow: hidden;
  }

  #ceoImage {
    width: 101%;
    height: auto;
  }

  #ceoImage:hover {
    transform: none;
  }

  .ceoContent {
    margin: 30px 0;
    padding: 30px 0;
    align-items: center;
    margin-left: 0px;
  }

  #ceoTitle {
    display: flex;
    width: 100%;

    font-size: 22px;
    margin-top: -30px;
  }

  #ceoDescription {
    font-size: 16px;
    text-align: center;
  }

  #togglesInfos {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  #toggleItem1,
  #toggleItem2,
  #toggleItem3 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  #rodrigoList {
    font-size: 14px;
    text-align: center;
  }

  .equipeSection {
    padding: 30px 0;

    flex-direction: column;
    align-items: center;
  }

  .colaboradoresDiv {
    flex-direction: column;
    align-items: center;

    width: 90%;
    gap: 0px;
  }

  #equipeCategoriaMedDiv {
    justify-content: center;
    width: 100%;

    gap: 0;
    margin-bottom: -45px;
  }

  #equipeCategoriaTecDiv {
    justify-content: center;
    width: 100%;

    gap: 0;
  }

  #lineShape {
    border-bottom: 1px solid #ffffff;
    margin-bottom: 35px;

    width: 260px;
    height: 1px;
  }

  #equipeCategoriaTitle,
  #equipeCategoriaTitle {
    font-size: 24px;
    text-align: center;
    margin-bottom: 20px;
  }

  .medicosDiv1 {
    gap: 80px;
    width: 100%;
    flex-wrap: wrap;
  }

  .medicosDiv2 {
    gap: 80px;
    width: 100%;
    flex-wrap: wrap;

    margin-top: -10px;
  }

  .tecnicosDiv {
    gap: 50px;
    width: 100%;
    flex-wrap: wrap;
  }
}

@media screen and (min-width: 480px) and (max-width: 768px) {
  .ceoSection {
    flex-direction: column;
    align-items: center;
    padding: 30px 0;
    margin-bottom: -30px;
  }

  #ceoImage {
    border-radius: 6px;
  }

  #ceoImage:hover {
    transform: none;
  }

  .ceoContent {
    margin-left: 0px;
    align-items: center;
  }

  #ceoTitle {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;
  }

  #ceoDescription {
    font-size: 16px;
    text-align: center;
  }

  #togglesInfos {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  #toggleItem1,
  #toggleItem2,
  #toggleItem3 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  #rodrigoList {
    font-size: 14px;
    text-align: center;
  }

  .equipeSection {
    padding: 30px 0;

    flex-direction: column;
    align-items: center;
  }

  .colaboradoresDiv {
    flex-direction: column;
    align-items: center;

    width: 90%;
    gap: 0px;
  }

  #equipeCategoriaMedDiv {
    justify-content: center;
    width: 100%;

    gap: 0;
    margin-bottom: -45px;
  }

  #equipeCategoriaTecDiv {
    justify-content: center;
    width: 100%;

    gap: 0;
  }

  #lineShape {
    border-bottom: 1px solid #ffffff;
    margin-bottom: 35px;

    width: 510px;
    height: 1px;
  }

  #equipeCategoriaTitle,
  #equipeCategoriaTitle {
    font-size: 24px;
    text-align: center;
    margin-bottom: 20px;
  }

  .medicosDiv1 {
    gap: 80px;
    width: 100%;
    flex-wrap: wrap;
  }

  .medicosDiv2 {
    gap: 80px;
    width: 100%;
    flex-wrap: wrap;

    margin-top: -10px;
  }

  .tecnicosDiv {
    gap: 80px;
    width: 100%;
    flex-wrap: wrap;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .ceoSection {
    flex-direction: column;
    align-items: center;
    padding: 30px 0;
    margin-bottom: -30px;
  }

  #ceoImage {
    border-radius: 6px;
  }

  .ceoContent {
    margin-left: 0px;
  }

  #ceoTitle {
    font-size: 30px;
  }

  #ceoDescription {
    font-size: 16px;
  }

  .equipeSection {
    padding: 30px 0;

    flex-direction: column;
    align-items: center;
  }

  .colaboradoresDiv {
    flex-direction: column;
    align-items: center;

    width: 90%;
    gap: 0px;
  }

  #equipeCategoriaMedDiv {
    justify-content: center;
    width: 100%;

    gap: 0;
    margin-bottom: -45px;
  }

  #equipeCategoriaTecDiv {
    justify-content: center;
    width: 100%;

    gap: 0;
  }

  #lineShape {
    border-bottom: 1px solid #ffffff;
    margin-bottom: 35px;

    width: 510px;
    height: 1px;
  }

  #equipeCategoriaTitle,
  #equipeCategoriaTitle {
    font-size: 24px;
    text-align: center;
    margin-bottom: 20px;
  }

  .medicosDiv1 {
    gap: 80px;
    width: 100%;
    flex-wrap: wrap;
  }

  .medicosDiv2 {
    gap: 80px;
    width: 100%;
    flex-wrap: wrap;

    margin-top: -10px;
  }

  .tecnicosDiv {
    gap: 80px;
    width: 100%;
    flex-wrap: wrap;
  }
}
