@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.contatoPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;

  height: 100%;
  padding: 50px 0;
  margin-bottom: -82px;
  gap: 60px;
}

.contatoDiv {
  width: 80%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  padding: 50px 0;

  background: linear-gradient(to left, rgba(0, 0, 0, 0.3), transparent);
  background-color: #1f497e;
}

.formContato {
  width: 90%;
  gap: 20px;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.pessoaInfo {
  display: flex;
  justify-content: space-between;

  gap: 20px;
  width: 100%;
}

input {
  width: 48%;
  height: 55px;
  padding: 10px;
  border: none;
  font-family: inherit;
}

textarea {
  width: 100%;
  height: 300px;
  padding: 10px;
  border: none;
  font-family: inherit;
}

@media screen and (max-width: 768px) {
  .contatoPage {
    background: linear-gradient(to left, rgba(0, 0, 0, 0.3), transparent);
    background-color: #1f497e;
  }

  .contatoDiv {
    width: 100%;
    gap: 20px;
    padding: 0px;

    background: linear-gradient(to left, rgba(0, 0, 0, 0), transparent);
    background-color: transparent;
  }

  .pessoaInfo {
    display: flex;
    flex-direction: column;

    gap: 20px;
    width: 100%;
  }

  input {
    width: 100%;
  }
}
