@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");

* {
  padding: 0;
  margin: 0;
}

.depoimentoSection {
  width: 80%;
  max-width: 1440px;
  padding: 80px 0;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.depoimentoTexts {
  width: 80%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;

  text-align: center;
}

#depoimentoTitle {
  font-family: "Inter";
  font-size: 1.23vw;
  /* font-size: 18px; */
  font-weight: 600;
  text-align: center;
  color: #999999;
}

#depoimento {
  font-family: "Inter";
  font-size: 1.5vw;
  /* font-size: 22px; */
  font-weight: 500;
  font-style: italic;
  text-align: center;
  color: #1f497e;
}

#pessoaCargoDiv {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

#nomePessoa {
  font-family: "Inter";
  font-size: 1.23vw;
  /* font-size: 18px; */
  font-weight: 600;
  text-align: center;
  color: #999999;
}

#cargo {
  font-family: "Inter";
  font-size: 0.96vw;
  /* font-size: 14px; */
  font-weight: 600;
  text-align: center;
  color: #999999;
}

#seta {
  max-width: 80%;
  min-width: 30px;
  cursor: pointer;
  transition: all 0.5s ease-in-out;
}

#seta:hover {
  transform: scale(1.2);
}

#btnSetas {
  border-style: none;
  background-color: #fff;
  width: 7%;
}

@media screen and (max-width: 768px) {
  .depoimentoSection {
    padding: 30px 0;
    width: 90%;
  }

  #depoimentoTitle {
    font-size: 14px;
  }

  #depoimento {
    font-size: 16px;
  }

  #nomePessoa {
    font-size: 14px;
  }

  #cargo {
    font-size: 11px;
  }

  #seta:hover {
    transform: none;
  }
}

@media screen and (min-width: 768px) and (max-width: 1240px) {
  .depoimentoSection {
    padding: 60px 0;
  }

  #depoimentoTitle {
    font-size: 15px;
  }

  #depoimento {
    font-size: 18px;
  }

  #nomePessoa {
    font-size: 15px;
  }

  #cargo {
    font-size: 12px;
  }
}

@media screen and (min-width: 1440px) {
  #depoimentoTitle {
    font-size: 18px;
  }

  #depoimento {
    font-size: 22px;
  }

  #nomePessoa {
    font-size: 18px;
  }

  #cargo {
    font-size: 14px;
  }
}
