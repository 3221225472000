@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.mainColaboradorCEO {
  display: flex;
  flex-direction: column;
  align-items: center;

  margin-bottom: -82px;

  background: linear-gradient(to left, rgba(0, 0, 0, 0.3), transparent);
  background-color: #1f497e;
}

.colaboradorContent {
  width: 80%;
  max-width: 1440px;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 60px;

  margin: 60px 0;
}

.colaboradorDivContent {
  display: flex;
  align-items: center;

  background-color: #fff;
}

#imgId {
  display: flex;
  height: 380px;
}

.colaboradorInfos {
  display: flex;
  flex-direction: column;
  align-items: start;
  padding: 30px;
  gap: 20px;

  margin-left: 20px;
}

#colaboradorTitle {
  font-family: "Inter";
  font-size: 42px;
  font-weight: 600;
  text-align: left;
  color: #1f497e;
}

#colaboradorCargo {
  font-family: "Inter";
  font-size: 18px;
  font-weight: 600;
  text-align: left;
  color: #1f497e;
  margin-top: -20px;
}

#colaboradorList {
  list-style: none;

  display: flex;
  flex-direction: column;
  gap: 10px;
}

#colaboradorDescription {
  font-family: "Inter";
  font-size: 16px;
  font-weight: 400;
  line-height: 1.3;
  text-align: justify;
  letter-spacing: 0.07em;
  color: #1f497e;
}

#colaboradorButton {
  font-family: "Inter";
  font-size: 16px;
  font-weight: 500;
  line-height: 1.3;
  text-align: left;
  letter-spacing: 0.07em;
  color: #999999;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 10px;

  border: 1px solid #999999;
  border-radius: 4px;
  background-color: #fff;

  cursor: pointer;
}

@media screen and (max-width: 480px) {
  .colaboradorDivContent {
    flex-direction: column;
  }

  #colaboradorImg {
    width: 100%;
    height: auto;
    overflow: hidden;
  }

  #imgId {
    width: 101%;
    height: auto;
  }

  .colaboradorContent {
    margin: 30px 0;
    margin-bottom: 60px;
    width: 90%;
  }

  .colaboradorInfos {
    height: 100%;

    align-items: center;
    padding: 15px;
    gap: 20px;
    margin-left: 0px;
  }

  #colaboradorTitle {
    width: 100%;
    display: flex;
    justify-content: center;
    text-align: center;
    font-size: 24px;
  }

  #colaboradorCargo {
    width: 100%;
    display: flex;
    justify-content: center;
    text-align: center;
    font-size: 15px;
    margin-top: -15px;
  }

  #colaboradorDescription {
    font-size: 16px;
    text-align: center;
  }
}

@media screen and (min-width: 480px) and (max-width: 768px) {
  .colaboradorDivContent {
    flex-direction: column;
    padding-top: 30px;
  }

  .colaboradorInfos {
    margin-left: 0px;
  }

  #colaboradorTitle {
    width: 100%;
    display: flex;
    justify-content: center;
    text-align: center;
    font-size: 30px;
  }

  #colaboradorCargo {
    width: 100%;
    display: flex;
    justify-content: center;
    text-align: center;
    font-size: 16px;
    margin-top: -15px;
  }

  #colaboradorDescription {
    font-size: 16px;
    text-align: center;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .colaboradorDivContent {
    flex-direction: column;
    padding-top: 30px;
  }

  #imgId {
    border-radius: 6px;
  }

  .colaboradorInfos {
    margin-left: 0px;
  }

  #colaboradorTitle {
    font-size: 30px;
  }

  #colaboradorCargo {
    font-size: 16px;
  }

  #colaboradorDescription {
    font-size: 16px;
  }
}
