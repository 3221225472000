@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.card {
  width: 310px;
  height: 200px;

  margin-bottom: 40px;
}

.cardImage {
  position: relative;
  height: 200px;
  overflow: hidden;

  cursor: pointer;
  transition: all 0.5s ease-in-out;
}

.cardImage:hover {
  transform: scale(1.07);
}

.imgMotion {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.cardContent {
  display: flex;
  justify-content: center;
}

#titleCardDark {
  font-family: "Inter";
  font-size: 16px;
  font-weight: 700;
  line-height: 1.1;
  text-align: center;
  letter-spacing: 0.08em;
  color: #1f497e;
  margin-top: 10px;
}

#titleCardLight {
  font-family: "Inter";
  font-size: 16px;
  font-weight: 600;
  line-height: 1.1;
  text-align: center;
  letter-spacing: 0.08em;
  color: #fff;
  margin-top: 10px;
}

@media screen and (max-width: 768px) {
  .card {
    margin-bottom: 10px;
  }

  #titleCardDark,
  #titleCardLight {
    font-size: 14px;
  }

  .cardImage:hover {
    transform: none;
  }
}
