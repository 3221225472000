@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");

* {
  padding: 0;
  margin: 0;
}

.footer {
  display: flex;
  justify-content: center;
  margin-top: 80px;

  background: linear-gradient(45deg, rgba(0, 0, 0, 0.7), transparent);
  background-color: #3a3a3a;
}

.footerSection {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 80px 0;
  width: 80%;
  max-width: 1440px;
  flex-wrap: wrap;
}

#linksTitle {
  font-family: "Inter";
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 14px;
  color: #fff;
}

#linkList {
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

#link {
  font-family: "Inter";
  font-size: 12px;
  font-weight: 400;
  color: #fff;
}

@media screen and (max-width: 768px) {
  .footerSection {
    flex-direction: column;
    gap: 50px;
  }

  #linkList {
    align-items: center;
  }

  #logoFooter {
    width: 130px;
  }

  .containerLinks {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  #linksTitle {
    font-family: "Inter";
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 14px;
    color: #fff;
  }

  #linkList {
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  #link {
    font-family: "Inter";
    font-size: 12px;
    font-weight: 400;
    color: #fff;
  }
}
