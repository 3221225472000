@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.mainBlog {
  display: flex;
  justify-content: center;
  padding: 80px 0;
}

.blogDiv {
  width: 80%;

  display: flex;
  justify-content: center;
  gap: 90px;
}

.postsSection {
  width: 70%;
}

.postContent {
  display: flex;
  flex-direction: column;
  align-items: start;

  gap: 60px;
}

#itemPostContent {
  list-style: none;

  display: flex;
  flex-direction: column;
  gap: 10px;
}

#postTitleContent {
  font-family: "Inter";
  font-size: 28px;
  font-weight: 700;
  color: #1f497e;
}

#postContentParagraph {
  font-family: "Inter";
  font-size: 16px;
  font-weight: 300;
  line-height: 1.3;
  text-align: justify;
  letter-spacing: 0.1em;

  color: #3b3b3b;
}

.postsSideBar {
  width: 20%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

#titleAntigos {
  font-family: "Inter";
  font-size: 22px;
  font-weight: 700;
  color: #1f497e;
}

#itemPostContentSideBar {
  list-style: none;

  display: flex;
  flex-direction: column;
  gap: 10px;
}

.postContentSideBar {
  display: flex;
  flex-direction: column;
  align-items: start;

  gap: 60px;
}

#postTitleSideBar {
  font-family: "Inter";
  font-size: 18px;
  font-weight: 700;
  color: #1f497e;
}

#postSideBarParagraph {
  font-family: "Inter";
  font-size: 12px;
  font-weight: 300;
  line-height: 1.3;
  text-align: justify;
  letter-spacing: 0.1em;

  color: #3b3b3b;
}
