@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.buttonDark {
  border: none;
  background-color: #1f497e;

  cursor: pointer;
  transition: 0.2s ease-in-out;
}

.buttonDark:hover {
  background-color: #3d77be;
}

.buttonLight {
  border: none;
  background-color: #3d77be;

  cursor: pointer;
  transition: 0.5s ease-in-out;
}

.buttonLight:hover {
  background-color: #071b33;
}

#btnIconImg {
  transform: rotate(180deg);
}

#btnPos {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px 60px;
  gap: 0px;

  transition: 0.5s ease-in-out;
}

#btnPos:hover {
  gap: 20px;
  padding: 6px 50px;
}

#btnText {
  font-family: "Inter";
  font-size: 16px;
  font-weight: 600;
  color: #fff;

  padding-left: 10px;
  padding-top: 2px;
}

@media screen and (max-width: 768px) {
  #btnPos {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4px 30px;
    margin-left: 6px;
    gap: 0px;

    transition: 0.5s ease-in-out;
  }

  #btnPos:hover {
    gap: 14px;
    padding: 4px 24px;
  }

  #btnText {
    font-family: "Inter";
    font-size: 12px;
    font-weight: 600;
    color: #fff;

    padding-left: 6px;
    padding-top: 1px;
  }
}
