@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");

* {
  padding: 0;
  margin: 0;
}

.card {
  width: 220px;
  height: 291.5px;
}

.cardImage {
  position: relative;
  width: 220px;
  height: 291.5px;
  overflow: hidden;

  cursor: pointer;
  transition: all 0.5s ease-in-out;
}

.cardImage:hover {
  transform: scale(1.05);
}

.imgMotion {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

#roleCard {
  font-family: "Inter";
  font-size: 12px;
  font-weight: 600;
  line-height: 1.1;
  text-align: left;
  letter-spacing: 0.05em;
  color: #999999;

  margin-top: 10px;
}

#titleCard {
  font-family: "Inter";
  font-size: 16px;
  font-weight: 600;
  line-height: 1.2;
  text-align: left;
  letter-spacing: 0.05em;
  color: #fff;

  margin-top: 5px;
}

@media screen and (max-width: 480px) {
  #titleCard {
    font-family: "Inter";
    text-align: center;
  }

  .cardImage:hover {
    transform: none;
  }

  #roleCard {
    text-align: center;
    display: flex;
    justify-content: center;
  }
}

@media screen and (min-width: 480px) and (max-width: 768px) {
  #titleCard {
    font-family: "Inter";
    text-align: center;
  }

  #roleCard {
    text-align: center;
    display: flex;
    justify-content: center;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
}
