@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.cover {
  position: relative;
  width: 100%;
  height: 500px;
  overflow: hidden;
}

.backgroundImage {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.logo {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 800px;
}

.textDiv {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.text {
  font-family: "Inter";
  font-size: 96px;
  font-weight: 600;
  color: #1f497e;
  text-align: center;
}

@media screen and (max-width: 480px) {
  .cover {
    position: relative;
    width: 100%;
    height: 220px;
  }

  .logo {
    max-width: 78%;
  }

  .text {
    font-size: 10.65vw;
  }
}

@media screen and (min-width: 480px) and (max-width: 768px) {
  .cover {
    position: relative;
    width: 100%;
    height: 300px;
  }

  .logo {
    max-width: 78%;
  }

  .text {
    font-size: 10.65vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .logo {
    max-width: 78%;
  }

  .text {
    font-size: 9.35vw;
  }
}
