@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.header {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;

  height: 65px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.headerContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 80%;
  max-width: 1440px;
}

.logo {
  width: 30px;
  height: auto;
}

.navList {
  list-style: none;
}

.navBar {
  display: flex;
  justify-content: space-between;
  width: 500px;
  gap: 30px;
}

.navBarMobile {
  display: none;
}

#checkbox_toggle[type="checkbox"] {
  display: none;
}

.hamburger {
  cursor: pointer;
  display: none;
  font-size: 24px;
  user-select: none;
}

.linkItem {
  display: flex;
  justify-content: center;
  align-items: center;
}

#homeLink {
  width: 44px;
}

#sobreLink {
  width: 44px;
}

#produtosLink {
  width: 65px;
}

#equipeLink {
  width: 50px;
}

#clientesLink {
  width: 55px;
}

#contatoLink {
  width: 56px;
}

.navLink {
  font-family: "Inter";
  font-size: 14px;
  font-weight: normal;
  color: #999999;

  cursor: pointer;
  transition: color 0.2s ease-in-out;
}

.navLink:hover {
  font-weight: 700;
  color: #3d3d3d;
}

.active {
  font-family: "Inter";
  font-size: 14px;
  font-weight: bold;
  color: #3d3d3d;

  cursor: pointer;
  text-decoration: none;
}

a {
  text-decoration: none;
}

.languages {
  display: flex;
  justify-content: start;
  align-items: center;

  width: 90px;
  height: 26px;
  padding-left: 10px;
  gap: 15px;

  position: fixed;
  top: 22px;
  right: 0px;
  z-index: 999;

  border-radius: 6px 0 0 6px;
  background-color: #fff;
}

.btnFlag {
  border-style: none;
  background-color: #fff;

  display: flex;
  justify-content: center;
  align-items: center;
}

.flagLang {
  width: 20px;
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .navBar {
    display: none;
  }

  .navBarMobile {
    display: block;
    position: absolute;
    background-color: #fff;
    z-index: 999;
    right: 0;
    left: 0;
    text-align: center;
    padding: 16px 0;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  .navBarMobile li:hover {
    display: inline-block;
    background-color: #fff;
    transition: 0.3s ease;
  }

  .navBarMobile li+li {
    margin-top: 18px;
  }

  .hamburger {
    display: block;
    color: #999999;
  }

  .languages {
    display: none;
  }

  .languagesMobile {
    display: flex;
    justify-content: start;
    align-items: center;

    width: 96px;
    height: 26px;
    padding-left: 10px;
    gap: 15px;

    position: fixed;
    top: 62px;
    right: 0px;
    z-index: 999;

    border-radius: 6px 0 0 6px;
    background-color: #fff;
  }
}