@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.cover {
  position: relative;
  width: 100%;
  height: 250px;
  overflow: hidden;

  display: flex;
  justify-content: center;
}

.backgroundImage {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.logoTitleDiv {
  position: absolute;

  display: flex;
  justify-content: center;
  align-items: center;

  gap: 30px;
  /* width: 80%; */
  max-width: 1280px;
  /* top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); */

  background-color: aquamarine;
}

.imgDiv {
  height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: azure;
}

.logo {
  height: 140px;

  background-color: aqua;
}

.textDiv {
  height: 250px;
  display: flex;
  align-items: center;
  max-width: 805px;

  background-color: antiquewhite;
}

.text {
  font-family: "Inter";
  font-size: 76px;
  font-weight: 600;
  color: #1f497e;
  text-align: left;
}

@media only screen and (max-width: 900px) {
  .logo {
    max-width: 90%;
  }

  .text {
    font-size: 10.65vw;
  }
}
