@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.main {
  margin-bottom: -80px;
}

.produtoSection {
  display: flex;
  justify-content: center;
}

#produtosDiv {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  width: 80%;
  max-width: 1440px;
  gap: 30px;
  padding: 70px 0;
}

@media screen and (max-width: 768px) {
  .main {
    margin-bottom: -50px;
  }

  #produtosDiv {
    width: 90%;
    padding: 30px 0;
    gap: 50px;
  }
}
