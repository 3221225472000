@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.btnDiv {
  display: flex;
  justify-content: start;

  width: 250px;
  padding: 6px 0;

  /* border: 1px solid #1f497e; */
}

.btnIcon {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;

  border: none;
  cursor: pointer;

  background-color: #fff;
}

.btnIconActive {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;

  border: none;
  cursor: pointer;

  background-color: #fff;
  margin-top: 1px;
}

#btnText {
  font-family: "Inter";
  font-size: 14px;
  font-weight: 600;
  color: #1f497e;
}

#iconImg {
  height: 18px;
  width: 18px;
}

#iconImgActive {
  height: 2px;
  width: 18px;
}

@media screen and (max-width: 480px) {
  .btnDiv {
    justify-content: center;
  }
}

@media screen and (min-width: 480px) and (max-width: 768px) {
  .btnDiv {
    justify-content: center;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
}
